<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <!-- <submenu-server menu="gaming"></submenu-server> -->

      <div class="submenu-content">
        <div v-if="error" class="alert alert-danger">{{ error }}</div>

        <!-- show page content -->
        <div v-if="item">
          <!-- page title -->
          <h2>{{item.intro}}</h2>
          <br/>

          <div v-for="b in item.body">

            <div v-if="b.type === 'heading'">              
              <div v-if="b.value === 'Inquiry Button'">
                <router-link class="btn btn-primary btn-lg" to="/contact-us/server">Inquiry/Setup Account</router-link>
                <br/><br/>
              </div>
              <div v-else>
                <h4>{{b.value}}</h4>
              </div>
            </div>

            <div v-else-if="b.type === 'paragraph'" class="mb-5">
              <p v-html="b.value"></p>
            </div>

            <div v-else-if="b.type === 'image'">
              <div class="flexbox">
                <!-- <img
                :src="b.value.large.src"
                :width="b.value.large.width"
                :height="b.value.large.height"> -->
                <img class="fleximg"
                :src="b.value.large.src">
              </div>
            </div>

            <!-- stream block :
                logo groups
            -->
            <div v-else-if="b.type === 'stream_block'"> 
              <div v-for="sb in b.value">

                <div class="float-start block">
                  <div v-if="sb.value.heading" class="heading">{{sb.value.heading}}</div>
                  <div v-if="sb.value.paragraph" class="mt-3"><p v-html="sb.value.paragraph"></p></div>
                  <div v-for="(itm, index) in sb.value.image_stream">
                    <img :src="itm.value.medium.src"
                    height="45" class="float-start pad">    
                  </div>
                  <div class="clear"></div> 
                </div>

              </div>
              <div class="clear"></div>
            </div>
            <!-- end of stream block-->

            
          </div>
        
        <!-- end of page content -->
      </div>

  </div>
  </div>
</template>

<script>
import SubmenuServer from '../../components/nav/SubmenuServer.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import axios from 'axios'
import { ref, onMounted } from 'vue'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'GamingSolutions',
  components: { SubmenuServer, BreadcrumbPage },
  setup() {
    const error = ref('')
    const item = ref({})
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Server", link: ""})
    breadcrumb_links.value.push({text: "Gaming Solutions", link: "GamingSolutions"})

    getAPI
    .get('/content/pages/52')
    .then(response => {
      item.value = response.data
    })
    .catch(err => {
      error.value = err
    })

    return { error, item, breadcrumb_links }
  }
}
</script>

<style scoped>
.clear {clear:both;}
.pad {margin-right:35px;}
.block {width:45%; margin: 50px 30px 10px 0;}
.block .heading {border-bottom: 1px solid #333; font-size:25px; }

.flexbox {
  display: flex;
  justify-content: flex-start;

}
.fleximg {
  flex: 1 1 auto;
}
</style>